<script lang="ts" setup>
import { computed } from 'vue';
import Modal from './Modal.vue';
import { hasAccess } from '../feature-manager';
import { useSubscriptionStore } from '../store/subscription';
import { FEATURES } from '../feature-manager';

defineEmits(['close']);
const subscriptionStore = useSubscriptionStore();

const TIER_TRIAL = 'Trial',
  TIER_START_UP = 'Start-up',
  TIER_LIGHT = 'Light',
  TIER_PROFESSIONAL = 'Professional',
  TIER_ENTERPRISE = 'Enterprise';

const TIER_HIERARCHY = [
  TIER_TRIAL,
  TIER_START_UP,
  TIER_LIGHT,
  TIER_PROFESSIONAL,
  TIER_ENTERPRISE,
];

const groupedCoreFeatures = computed(() => {
  const coreFeatures = subscriptionStore.companyData?.core_features;
  const modules = subscriptionStore.companyData?.modules;
  return coreFeatures?.reduce((acc, feature) => {
    const group = modules.find((module) =>
      module.features.includes(feature)
    ) ?? {
      name: 'Other',
      'font-awesome-icon': 'fa-cogs',
      conditional: false,
    };

    if (!acc[group.name]) {
      acc[group.name] = {
        name: group.name,
        features: [],
        icon: group['font-awesome-icon'],
        conditional: group.conditional,
      };
    }
    acc[group.name].features.push(feature);
    return acc;
  }, {});
});

const getFormattedTiers = (tiers) => {
  return Object.keys(tiers)
    .filter((key) => key !== TIER_TRIAL)
    .map((key) => {
      return {
        name: key,
        order: TIER_HIERARCHY.indexOf(key),
        ...tiers[key],
      };
    });
};

const getLowerTierHeadings = (tier, tiers) => {
  const lowerTiers = [
    {
      name: 'Core Features',
      icon: 'fa-atom',
    },
  ];
  tiers.forEach((t) => {
    if (t.order < tier.order) {
      lowerTiers.push({
        name: t.name,
        icon: t['font-awesome-icon'],
      });
    }
  });
  return lowerTiers;
};

const getFeaturesInCurrentTier = (
  tier,
  tiers,
  lowerTierFeatures,
  lowerTierFeaturesWithAccess
) => {
  const higherTierFeatures = tiers
    .filter((t) => t.order > tier.order)
    .flatMap((t) => t.features);

  const bespokeAuthorizedFeatures = higherTierFeatures.filter((feature) =>
    hasAccess(feature)
  );
  let featuresInTier;
  const userTier = tiers.find(
    (tier) => tier.name === subscriptionStore.userTier
  );
  if (tier.name === userTier.name) {
    featuresInTier = tier.features.filter((feature) => {
      return (
        !lowerTierFeatures.includes(feature) &&
        hasAccess(feature) &&
        !lowerTierFeaturesWithAccess.includes(feature)
      );
    });

    const featureSet = new Set(featuresInTier);
    bespokeAuthorizedFeatures.forEach((feature) => {
      if (!featureSet.has(feature)) featureSet.add(feature);
    });
    Object.values(FEATURES).forEach((feature) => {
      if (
        !featureSet.has(feature) &&
        !lowerTierFeatures.includes(feature) &&
        hasAccess(feature)
      ) {
        featureSet.add(feature);
      }
    });
    featuresInTier = Array.from(featureSet);
  } else {
    featuresInTier = tier.features.filter((feature) => {
      return (
        !lowerTierFeatures.includes(feature) &&
        !lowerTierFeaturesWithAccess.includes(feature)
      );
    });
  }
  return featuresInTier;
};

const personalizedTiers = computed(() => {
  const tiers = getFormattedTiers(subscriptionStore.companyData?.tiers);
  const lowerTierFeaturesWithAccess: any[] = [];
  const filteredTiers = tiers.map((tier) => {
    const lowerTierHeadings = getLowerTierHeadings(tier, tiers);
    const lowerTierFeatures = tiers
      .filter((t) => t.order < tier.order)
      .reduce((acc, t) => {
        return [...acc, ...t.features];
      }, []);
    const featuresInTier = getFeaturesInCurrentTier(
      tier,
      tiers,
      lowerTierFeatures,
      lowerTierFeaturesWithAccess
    );

    lowerTierFeaturesWithAccess.push(
      ...featuresInTier.filter((feature) => hasAccess(feature))
    );

    const groupedFeatures = featuresInTier.reduce((acc, feature) => {
      const modules = subscriptionStore.companyData?.modules || [];
      const module = modules.find((m) => m.features.includes(feature)) || {
        name: 'Other',
        'font-awesome-icon': 'fa-cogs',
      };
      const groupName = module.name;
      if (!acc[groupName]) {
        acc[groupName] = {
          name: groupName,
          features: {},
          icon: module['font-awesome-icon'],
        };
      }
      let featureGroupName = feature.split('.')[0] ?? feature;
      let featureName = feature;
      if (featureGroupName === groupName.toLowerCase()) {
        featureGroupName = feature.split('.')[1] ?? feature;
      }
      if (feature !== featureGroupName) {
        const i = feature.indexOf(featureGroupName);
        const dotIndex = feature.indexOf('.', i);
        featureName = feature.substring(dotIndex + 1);
      }
      if (!acc[groupName].features[featureGroupName]) {
        acc[groupName].features[featureGroupName] = [];
      }
      acc[groupName].features[featureGroupName].push(featureName);
      return acc;
    }, {});
    // Move 'Other' group to the end
    const otherGroup = groupedFeatures['Other'];
    delete groupedFeatures['Other'];
    if (otherGroup) {
      groupedFeatures['Other'] = otherGroup;
    }
    return {
      ...tier,
      groups: groupedFeatures,
      lower_tiers: lowerTierHeadings,
    };
  });
  return filteredTiers;
});

const sanitizeName = (name: string) => {
  const aliases = subscriptionStore.companyData?.feature_aliases;
  const aliasedName = aliases[name] ?? name;
  const withoutModule = aliasedName.split(/\.(.+)/)[1] ?? aliasedName;
  const sanitzedName = withoutModule
    .replace(/[.]/g, ': ')
    .replace(/[_\-]/g, ' ')
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());
  return sanitzedName;
};

const currentTier = computed(() => {
  return personalizedTiers.value.find(
    (tier) => tier.name === subscriptionStore.userTier
  );
});
</script>

<template>
  <Modal
    :show="true"
    :threeFifth="true"
    @close="subscriptionStore.toggleTiersModal"
    style="z-index: 999999"
  >
    <div slot="header" class="d-flex">
      <h4>Datanest Offering</h4>
    </div>
    <div>
      <div class="card">
        <div
          class="d-flex card-header align-items-center py-2 text-white bg-primary"
        >
          <i class="mx-2 fas fa-atom" />
          <h5 class="card-title mb-0">Core Features</h5>
        </div>
        <div class="d-flex gap-2 card-body">
          <div
            v-for="(group, index) in groupedCoreFeatures"
            :key="index"
            style="width: 15%"
          >
            <div class="card">
              <div
                class="card-header text-primary bg-light d-flex gap-2 align-items-center"
              >
                <i class="fas" :class="group.icon" />
                <h6>{{ group.name }}</h6>
                <sup
                  v-if="group.conditional"
                  class="text-danger fs-5"
                  v-tooltip="'Add on module.'"
                  >*</sup
                >
              </div>
              <div class="card-body">
                <ul class="list-group mt-2 py-2" style="list-style-type: None">
                  <li v-for="feature in group.features">
                    {{ sanitizeName(feature) }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div
          class="d-flex card-header align-items-center py-2 text-white bg-primary"
        >
          <i class="mx-2 fas fa-layer-group" />
          <h5 class="card-title mb-0">Subscription Tiers</h5>
        </div>
        <div class="card-body d-flex gap-2 mt-2" style="overflow-y: auto">
          <div
            style="width: 22%"
            v-for="tier in personalizedTiers"
            class="card"
            :class="{
              'shadow-lg border': tier.name === currentTier.name,
            }"
          >
            <div
              class="d-flex card-header align-items-center py-2 gap-2"
              :class="{
                'bg-primary text-white': tier.name === currentTier.name,
                'bg-light text-primary': tier.name !== currentTier.name,
              }"
            >
              <i class="fas" :class="tier['font-awesome-icon']" />
              <h5 class="card-title mb-0">{{ tier.name }}</h5>
              <i
                v-if="tier.name === currentTier.name"
                class="text-success fas fa-check fs-5"
              />
            </div>
            <div class="card-body">
              <h6>Limits</h6>
              <div>
                <label>Data/year:</label>&nbsp;
                <span>{{
                  tier['default-limits']?.['annual-data-allowance']
                    ? tier['default-limits']?.['annual-data-allowance'] + 'GB'
                    : 'Custom'
                }}</span>
              </div>
              <hr />
              <h6>Features</h6>
              <ul class="list-group list-group-flush">
                <div class="ms-3">
                  <label>Features from:</label>
                  <ul style="list-style-type: None">
                    <li
                      class="d-flex text-primary align-items-center gap-2"
                      v-for="lowerTier in tier.lower_tiers"
                    >
                      <i class="fas" :class="lowerTier.icon" />
                      <label>{{ lowerTier.name }}</label>
                    </li>
                  </ul>
                </div>
                <div class="d-flex mt-1 align-items-center gap-1">
                  <hr />
                  <i class="fas fa-plus-circle text-primary fs-6" />
                  <hr />
                </div>
                <li
                  v-for="(group, index) in tier.groups"
                  :key="index"
                  class="list-group-item"
                >
                  <div class="d-flex gap-2 align-items-center text-primary">
                    <i class="fas" :class="group.icon" />
                    <label>{{ group.name }}</label>
                  </div>
                  <ul style="list-style-type: None">
                    <li v-for="featureGroup in Object.keys(group.features)">
                      <div v-if="group.features[featureGroup].length > 1">
                        <div class="d-flex flex-column">
                          <label>{{ sanitizeName(featureGroup) }}:</label>
                          <ul>
                            <li
                              v-for="feature in group.features[
                                featureGroup
                              ].filter((feature) => feature !== featureGroup)"
                              class="d-flex gap-2 align-items-center"
                            >
                              <label>- {{ sanitizeName(feature) }}</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div v-else>
                        <label>{{
                          sanitizeName(group.features[featureGroup][0])
                        }}</label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
