<script lang="ts" setup>
import { useSubscriptionStore } from '../store/subscription';
import AlertBox from './AlertBox.vue';
import TiersModal from './SubscriptionsModal.vue';

defineProps<{
  featureName: string;
}>();
const subscriptionStore = useSubscriptionStore();
</script>

<template>
  <AlertBox v-if="subscriptionStore.userTier" type="warning">
    <div class="d-flex flex-row align-items-center">
      <span>
        The {{ featureName }} feature is not available in your subscription
        tier. Please upgrade to access this feature.
      </span>
      <button
        class="btn btn-primary ms-2"
        @click.prevent="subscriptionStore.toggleTiersModal"
      >
        Compare Plans
      </button>
    </div>
  </AlertBox>
</template>
